<template>
  <AppModal v-model="isModalOpen" class="max-w-[500px] w-full" @close="countShow">
    <div class="flex flex-col items-center gap-8">
      <div class="flex flex-col gap-2">
        <p class="text-base text-main font-medium whitespace-pre-line text-center">
          Для того чтобы иметь возможность знать все подробности по прошедшим рассылкам подпишитесь на наш сервисный ТГ бот
        </p>
        <p class="text-base text-primary font-medium whitespace-pre-line text-center">
          {{ telegramBot }}
        </p>
      </div>
      <AppButton
        label="Подключить"
        size="large"
        :function="connectTelegram"
      />
    </div>
  </AppModal>
</template>

<script setup lang="ts">
import type { Profile } from '@/types'
import profileApi from '@/api/profile'
import userApi from '@/api/user'
import { AppButton, AppModal } from '@/UI'
import { openLink } from '@/utils/openLink'

const project = inject('project') as ComputedRef<'zm' | 'sp' | 'sd'>
const profile = useUser() as Ref<Profile>
const isModalOpen = ref(false)

const telegramBot = computed(() => {
  const telegramMap = {
    zm: '@zazu_media_bot',
    sp: '@smspobeda_bot',
    sd: '@smsdar_bot',
  }

  return telegramMap[project.value]
})

async function connectTelegram() {
  try {
    const { url } = await profileApi.setTelegram()
    if (url) {
      openLink(url)
      isModalOpen.value = false
    }
  } catch (error) {
    console.error(error)
  }
}

async function countShow() {
  try {
    await userApi.countShowTimeTgPopup()
  } catch (error) {
    console.error(error)
  }
}

watch(() => profile.value, (newProfileData) => {
  if (newProfileData) {
    if (Object.prototype.hasOwnProperty.call(profile.value, 'telegram_id')
      && Object.prototype.hasOwnProperty.call(profile.value, 'show_telegram_bot_popup')
      && (profile.value.show_telegram_bot_popup)
      && (!profile.value.telegram_id)
    ) {
      isModalOpen.value = true
    }
  }
})
</script>
