<template>
  <AppModal v-model="isModalOpen" class="max-w-[500px] w-full">
    <div class="flex flex-col items-center gap-5">
      <h2 v-if="title" class="text-main text-xl font-medium" v-html="title" />
      <p class="text-base text-main whitespace-pre-line text-center" v-html="text" />
      <AppButton
        label="Больше не показывать"
        severity="secondary"
        :function="() => closePopup()"
      />
    </div>
  </AppModal>
</template>

<script setup lang="ts">
import type { Profile } from '@/types'
import { useUserCRUDStore } from '@/store/user'
import { AppButton, AppModal } from '@/UI'

const { setBonusDontShowAgain } = useUserCRUDStore()

const isModalOpen = ref(false)
const profile = useUser() as Ref<Profile>
const title = ref('')
const text = ref('')
const key = ref('')

async function closePopup() {
  await setBonusDontShowAgain({
    id: key.value,
    onlyCount: false,
  })
  isModalOpen.value = false
}

watch(() => profile.value, (newProfileData) => {
  if (newProfileData) {
    if (Object.prototype.hasOwnProperty.call(profile.value, 'custom_news')
      && !Array.isArray(profile.value.custom_news)
      && Object.prototype.hasOwnProperty.call(profile.value.custom_news, 'key')
      && Object.prototype.hasOwnProperty.call(profile.value.custom_news, 'text')
    ) {
      key.value = profile.value.custom_news.key
      title.value = profile.value.custom_news.title || ''
      text.value = profile.value.custom_news.text
      isModalOpen.value = true
    }
  }
})
</script>
