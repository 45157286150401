/**
 * Безопасно открывает URL в новой или текущей вкладке браузера
 * @param url - URL для открытия
 * @param newTab - открыть в новой вкладке (по умолчанию true)
 * @throws {Error} если URL невалидный или пустой
 */
export function openLink(url: string, newTab = true): void {
  // Проверка на валидность URL
  if (!url?.trim()) {
    throw new Error('Ссылка не может быть пустой')
  }

  try {
    // Проверяем и нормализуем URL
    const safeUrl = new URL(url).toString()
    // Проверяем, является ли устройство iOS/iPadOS
    const isIOS = navigator.platform.startsWith('iP')
      || (navigator.platform.startsWith('Mac'))
    if (isIOS && newTab) {
      // Safari на iOS: используем window.open
      try {
        const newWindow = window.open()

        if (newWindow) {
          newWindow.opener = null // Предотвращаем доступ нового окна к opener
          newWindow.location.href = safeUrl
        } else {
          // Если popup заблокирован, используем location
          window.location.href = safeUrl
        }
      // eslint-disable-next-line unused-imports/no-unused-vars, ts/no-unused-vars
      } catch (e) {
        // Запасной вариант, если window.open не сработал
        window.location.href = safeUrl
      }
    } else {
      // Для остальных браузеров используем программно созданный элемент <a>
      const link = document.createElement('a')
      link.href = safeUrl
      link.target = newTab ? '_blank' : '_self'
      link.rel = 'noopener noreferrer nofollow' // Усиленная безопасность

      // Добавляем дополнительные меры безопасности
      if (newTab) {
        link.setAttribute('referrerpolicy', 'no-referrer')
      }

      // Делаем элемент невидимым
      link.style.display = 'none'

      // Добавляем в DOM, кликаем и сразу удаляем
      document.body.appendChild(link)
      link.click()
      requestAnimationFrame(() => {
        document.body.removeChild(link)
      })
    }
  } catch (error) {
    throw new Error('Ошибка открытия ссылки', error)
  }
}
