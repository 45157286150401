import auth from '@/api/config'
import { updatePreset } from '@primevue/themes'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import primelocale from 'primelocale/ru.json'
import PrimeVue from 'primevue/config'

import ConfirmationService from 'primevue/confirmationservice'
import ToastService from 'primevue/toastservice'
import Tooltip from 'primevue/tooltip'
import { createApp, ref } from 'vue'
import { VueQueryPlugin } from 'vue-query'
import { addRollbarContext } from '../rollbar.config'
import RollbarPlugin from '../rollbar.plugin'

import App from './App.vue'
import basePreset from './presets/base'
import sdPreset from './presets/sd'
import spPreset from './presets/sp'
import zmPreset from './presets/zm'
import router from './router'

import './assets/styles/tailwind.scss'
import './assets/styles/main.sass'
import 'primeicons/primeicons.css'
import '@vuepic/vue-datepicker/dist/main.css'

const project = ref('')
const app = createApp(App)

app.config.warnHandler = (msg, vm, trace) => {
  if (msg.includes('Component inside <Transition> renders non-element root node that cannot be animated')) {
    // Игнорируем это предупреждение надо потом пофиксить TODO
    return
  }
  // Показываем все остальные предупреждения
  console.warn(`[Vue warn]: ${msg}\nTrace: ${trace}`)
}

const mapPreset: Record<string, any> = {
  zm: zmPreset,
  sp: spPreset,
  sd: sdPreset,
}

const themeMap: Record<string, () => Promise<any>> = {
  zm: () => import('./assets/styles/zazu.scss?inline'),
  sp: () => import('./assets/styles/smspobeda.scss?inline'),
  sd: () => import('./assets/styles/smsdar.scss?inline'),
}

function updateMetaInfo(preset: string) {
  const setLinkTag = (relAttribute: string, hrefPrefix: string) => {
    const links = document.querySelectorAll(`link[rel="${relAttribute}"]`)
    links.forEach((link) => {
      const htmlLink = link as HTMLLinkElement
      const url = new URL(htmlLink.href)
      htmlLink.href = `${hrefPrefix}${preset}${url.pathname}`
    })
  }

  setLinkTag('apple-touch-icon', '/img/icons/')
  setLinkTag('apple-touch-startup-image', '/img/icons/')
}

async function handleUpdatePreset(preset: string) {
  project.value = preset
  const themeImport = themeMap[preset] || themeMap.zm

  let styleElement = document.getElementById('theme-style') as HTMLStyleElement
  if (!styleElement) {
    styleElement = document.createElement('style')
    styleElement.id = 'theme-style'
    document.head.appendChild(styleElement)
  }

  try {
    const module = await themeImport()
    styleElement.textContent = module.default
  } catch (error) {
    console.error('Ошибка загрузки CSS', error)
    return
  }

  document.body.className = ''
  document.body.classList.add(project.value)
  updatePreset(mapPreset[preset])
  updateMetaInfo(preset)
}

function determineProject() {
  const hostname = window.location.hostname
  const searchParams = new URLSearchParams(window.location.search)

  if (hostname.includes('smsdar') || searchParams.has('sd')) {
    return 'sd'
  } else if (hostname.includes('smspobeda') || searchParams.has('sp')) {
    return 'sp'
  } else if (hostname.includes('zazu') || searchParams.has('zm')) {
    return 'zm'
  } else {
    return 'zm'
  }
}
window.addEventListener('vite:preloadError', () => {
  window.location.reload()
})
document.addEventListener('DOMContentLoaded', async () => {
  // Проверяем наличие token в URL
  const urlParams = new URLSearchParams(window.location.search)
  const urlToken = urlParams.get('token')

  if (window.location.pathname.includes('auth') && urlToken) {
    localStorage.removeItem('token')
  } else {
    const token = localStorage.getItem('token')
    if (token && !token.startsWith('"')) {
      localStorage.setItem('token', `"${token}"`)
    }
  }
  if (window.location.pathname === '/register/telegram') {
    window.location.href = '/auth/register/telegram'
  }
  if (window.location.pathname === '/register/phone') {
    window.location.href = '/auth/register/phone'
  }
  if (window.location.pathname === '/register/email') {
    window.location.href = '/auth/register/email'
  }

  project.value = determineProject()

  const pinia = createPinia()
  pinia.use(piniaPluginPersistedstate)

  app
    .use(pinia)
    .use(router)
    .use(PrimeVue, {
      locale: primelocale.ru,
      theme: {
        preset: basePreset,
        options: {
          darkModeSelector: '.dark',
        },
      },
    })
    .use(ToastService)
    .use(ConfirmationService)
    .use(VueQueryPlugin)
    .use(auth)
    .use(RollbarPlugin)
    .directive('tooltip', Tooltip)

  app.provide('updatePreset', handleUpdatePreset)
  await handleUpdatePreset(project.value)
  app.provide('project', project)
  app.mount('#app')

  addRollbarContext(app)
})

const useGlobals = () => app.config.globalProperties

export { app, project, useGlobals }
