<template>
  <AppModal v-model="isModalOpen" class="max-w-[740px] w-full">
    <div class="flex flex-col items-center gap-5">
      <h2 v-if="title" class="text-main text-xl font-medium">
        {{ title }}
      </h2>
      <p class="text-base text-main whitespace-pre-line text-center">
        {{ text }}
      </p>
      <AppButton
        label="Больше не показывать"
        severity="secondary"
        :function="() => closePopup()"
      />
    </div>
  </AppModal>
</template>

<script setup lang="ts">
import type { Profile } from '@/types'
import newsApi from '@/api/news'
import userApi from '@/api/user'
import { AppButton, AppModal } from '@/UI'

const isModalOpen = ref(false)
const profile = useUser() as Ref<Profile>
const title = ref('')
const text = ref('')

async function closePopup() {
  await userApi.setNewsReaded()
  isModalOpen.value = false
}

watch(() => profile.value, async (newProfileData) => {
  if (newProfileData) {
    if (profile.value.has_unread_news) {
      try {
        const { data, success } = await newsApi.getLastNews()
        if (success) {
          if (typeof data === 'string') text.value = data
          else {
            title.value = data.title
            text.value = data.text
          }
          isModalOpen.value = true
        }
      } catch (error) {
        console.error(error)
      }
    }
  }
})
</script>
