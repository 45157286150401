<template>
  <Button :loading="isLoading" class="[&>.p-button-label]:whitespace-nowrap" @click="handleClick">
    <!-- Прокидываем scoped slots -->
    <template
      v-for="(slotName, i) in Object.keys($slots)"
      :key="i"
      #[slotName]="scope"
    >
      <slot :name="slotName" v-bind="scope" />
    </template>
  </Button>
</template>

<script setup lang="ts">
import Button from 'primevue/button'

interface Props {
  function?: () => Promise<any>
}
const props = defineProps<Props>()

const emit = defineEmits(['click'])

const isLoading = ref(false)

async function handleClick(event: Event) {
  if (props.function) {
    try {
      isLoading.value = true
      await props.function()
    } catch (error) {
      throw error
    } finally {
      isLoading.value = false
    }
  } else {
    emit('click', event)
  }
}
</script>

<style lang="sass" scoped>
.p-button
  @apply rounded-lg
  @apply border-none hover:border-none !important
  &-text
    @apply text-button-text text-sm hover:text-primary
</style>
