<template>
  <Dialog
    v-model:visible="visible"
    class="dialog"
    modal
    dismissable-mask
    :closable="false"
    :append-to="appendTo"
    @hide="emits('close')"
  >
    <span class="close cursor-pointer" @click="visible = false" />
    <slot />
  </Dialog>
</template>

<script setup lang="ts">
import Dialog from 'primevue/dialog'

defineProps({
  appendTo: {
    type: String,
    default: 'body',
  },
})
const emits = defineEmits(['close'])
const visible = defineModel({ default: false })

watch(visible, (val) => {
  if (val) {
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth
    if (scrollbarWidth > 0) {
      document.body.style.setProperty('--p-scrollbar-width', `${scrollbarWidth}px`)
    } else {
      document.body.style.removeProperty('--p-scrollbar-width')
    }
  }
})
</script>

<style scoped lang="sass">
.close
  @apply absolute right-4 top-4 w-6 h-6
  &:hover
    &:before,
    &:after
      background: var(--primary-DEFAULT)
      transition: .15s all
  &:before,
  &:after
    @apply absolute left-[11px] h-6 w-[2px]
    content: ""
    background-color: #313131
  &:before
    @apply rotate-45
  &:after
    @apply -rotate-45
</style>
