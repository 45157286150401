import Api from './api'

class ProfileApi extends Api {
  URL = '/profile'

  async getProfile() {
    const path = `${this.URL}/get`
    return await super.get(path)
  }

  async updateProfile(data: object) {
    const path = `${this.URL}/update`
    return await super.post(path, data, null)
  }

  async checkReferralNotification() {
    const path = `${this.URL}/checkReferralNotification`
    return await super.post(path)
  }

  async setTestTelegram(data: object) {
    const path = `${this.URL}/setTestTelegram`
    return await super.post(path, data)
  }

  async setTelegram() {
    const path = `${this.URL}/setTelegram`
    return await super.post(path)
  }
}

const profileApi = new ProfileApi()
export default profileApi
