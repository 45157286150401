import Api from './api'

class UserApi extends Api {
  URL = '/user'

  async getPromos() {
    return await super.get(`${this.URL}/getPromos`)
  }

  async setBonusDontShowAgain(data: object) {
    return await super.post(`${this.URL}/setBonusDontShowAgain`, data)
  }

  async setNewsReaded() {
    return await super.post(`${this.URL}/news_readed`)
  }

  async countShowTimeTgPopup() {
    return await super.post(`${this.URL}/countShowTimeTgPopup`)
  }
}

const userApi = new UserApi()
export default userApi
