import userApi from '@/api/user'
import { acceptHMRUpdate, defineStore } from 'pinia'

interface Promo {
  id: number
  name: string
  // добавьте другие необходимые поля
}

export const useUserCRUDStore = defineStore('userCRUD', {
  state: () => ({
    promos: [] as Promo[],
  }),
  actions: {
    async getPromos() {
      try {
        const { data, success } = await userApi.getPromos()
        if (success) this.promos = data
      } catch (error) {
        throw error
      }
    },

    setBonusDontShowAgain({ type, id, onlyCount }: { type?: string, id: number | string, onlyCount: boolean }) {
      return userApi.setBonusDontShowAgain({
        id: id || type,
        onlyCount,
      })
    },
  },
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useUserCRUDStore, import.meta.hot))
