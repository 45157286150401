import type { App } from 'vue'
import Rollbar from 'rollbar'

const rollbarConfig = new Rollbar({
  accessToken: 'f2597509b36549e0b3c569f7b6153ac0',

  enabled: import.meta.env.MODE === 'production',

  captureUncaught: true,
  captureUnhandledRejections: true,

  autoInstrument: {
    network: true,
    log: true,
    dom: true,
    navigation: true,
    connectivity: true,
    contentSecurityPolicy: true,
    errorOnContentSecurityPolicy: true,
  },

  payload: {
    environment: import.meta.env.MODE,
    client: {
      javascript: {
        code_version: import.meta.env.VITE_APP_VERSION || '1.0.0',
        source_map_enabled: true,
        guess_uncaught_frames: true,
      },
    },
  },

  logLevel: 'error',

  maxItems: 10,
  itemsPerMinute: 60,

  ignoredMessages: [
    'Script error',
    'Network error',
  ],
} as Rollbar.Configuration)

function addRollbarContext(app: App) {
  const pinia = app.config.globalProperties.$pinia

  interface ExtraData {
    vm?: any
    [key: string]: any
  }

  app.config.globalProperties.$rollbar = {
    error: (error: Error, extra: ExtraData = {}) => {
      rollbarConfig.error(error, {
        custom: {
          ...extra,
          componentData: extra?.vm?.$data,
          componentProps: extra?.vm?.$props,
          componentRefs: extra?.vm?.$refs,
          componentAttrs: extra?.vm?.$attrs,
        },
      })
    },
  }

  rollbarConfig.configure({
    transform: (payload: any) => {
      try {
        payload.custom = {
          ...payload.custom,
          platform: 'vue3',
          userAgent: navigator.userAgent,
          viewport: {
            width: window.innerWidth,
            height: window.innerHeight,
          },
          url: window.location.href,
          timestamp: new Date().toISOString(),
        }

        if (pinia?.state?.value) {
          payload.custom.store = pinia.state.value
          payload.custom.storeJson = JSON.stringify(pinia.state.value)
        }

        try {
          payload.custom.session = {
            localStorage: Object.fromEntries(
              Object.keys(localStorage).map(key => [key, localStorage.getItem(key)]),
            ),
            sessionStorage: Object.fromEntries(
              Object.keys(sessionStorage).map(key => [key, sessionStorage.getItem(key)]),
            ),
          }
          payload.custom.sessionJson = JSON.stringify({
            localStorage,
            sessionStorage,
          })
        } catch (e) {
          console.error(e)
        }
      } catch (error) {
        console.error(error)
      }

      return payload
    },
  })
}

export { addRollbarContext, rollbarConfig as rollbar }
