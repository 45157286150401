import type { App } from 'vue'
import { rollbar } from './rollbar.config'

const rollbarPlugin = rollbar

export default {
  install(app: App) {
    app.config.errorHandler = (error: any, vm: any, info: any) => {
      rollbar.error(error, { info })
    }
    app.provide('rollbar', rollbarPlugin)
  },
}
